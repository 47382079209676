import {useEffect, useMemo, useState} from "react";
import {Chip, Card, CardBody, CardHeader, CardFooter, Typography, Tooltip} from "@material-tailwind/react";
import {Link} from "react-router-dom";
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/24/outline";

export default function TryFeature(factory, deps) {

    const videos = useMemo(() => {
        return [
            {
                id: 1,
                engTitle: "Why some people are always late",
                twTitle: "為什麼有人總是遲到？",
                thumbnail: "https://i3.ytimg.com/vi/uk-JuzGlSgs/maxresdefault.jpg",
                url: "https://www.youtube.com/watch?v=uk-JuzGlSgs",
                frequency: {
                    "ability": 1,
                    "able": 2,
                    "about": 4,
                    "accept": 1,
                    "actually": 2,
                    "agreeableness": 1,
                    "air": 1,
                    "all": 3,
                    "allow": 1,
                    "alone": 1,
                    "along": 1,
                    "alter": 1,
                    "always": 2,
                    "american": 1,
                    "annoy": 3,
                    "another": 1,
                    "anxious": 1,
                    "aren": 1,
                    "arrive": 1,
                    "aside": 1,
                    "ask": 2,
                    "assume": 1,
                    "author": 1,
                    "aware": 1,
                    "bake": 1,
                    "base": 1,
                    "because": 2,
                    "become": 1,
                    "before": 1,
                    "behaviour": 2,
                    "belief": 1,
                    "believe": 1,
                    "bell": 1,
                    "better": 2,
                    "big": 1,
                    "birth": 1,
                    "bit": 3,
                    "bore": 1,
                    "brain": 1,
                    "call": 2,
                    "cannot": 1,
                    "certain": 1,
                    "challenge": 1,
                    "change": 2,
                    "check": 1,
                    "choose": 1,
                    "clear": 1,
                    "clock": 2,
                    "come": 3,
                    "complete": 1,
                    "concern": 1,
                    "confession": 1,
                    "conscientious": 2,
                    "conscientiousness": 3,
                    "conscious": 1,
                    "consequence": 5,
                    "correct": 1,
                    "curve": 1,
                    "damn": 1,
                    "daughter": 1,
                    "day": 2,
                    "deadline": 7,
                    "decide": 1,
                    "deeply": 1,
                    "degree": 1,
                    "describe": 1,
                    "design": 1,
                    "desk": 2,
                    "destine": 1,
                    "detail": 1,
                    "development": 1,
                    "different": 2,
                    "distract": 1,
                    "doesn": 3,
                    "don": 3,
                    "down": 1,
                    "each": 2,
                    "early": 2,
                    "easily": 1,
                    "effectively": 3,
                    "effort": 1,
                    "else": 1,
                    "end": 2,
                    "engross": 2,
                    "estimate": 1,
                    "even": 3,
                    "event": 1,
                    "every": 1,
                    "everybody": 1,
                    "everything": 2,
                    "example": 1,
                    "excite": 1,
                    "expect": 1,
                    "expectation": 1,
                    "experience": 1,
                    "experiment": 1,
                    "external": 1,
                    "extroversion": 1,
                    "fact": 1,
                    "familiar": 1,
                    "feel": 2,
                    "few": 2,
                    "finish": 1,
                    "firm": 1,
                    "five": 2,
                    "focus": 1,
                    "force": 1,
                    "form": 2,
                    "forver": 1,
                    "found": 2,
                    "friend": 1,
                    "friendship": 1,
                    "frustrate": 1,
                    "fulfill": 1,
                    "full": 1,
                    "future": 1,
                    "gathering": 1,
                    "gene": 1,
                    "get": 5,
                    "go": 3,
                    "good": 3,
                    "grace": 2,
                    "group": 1,
                    "habit": 1,
                    "hand": 1,
                    "happen": 1,
                    "hardwired": 1,
                    "head": 1,
                    "help": 2,
                    "helpful": 1,
                    "high": 1,
                    "hopefully": 1,
                    "how": 3,
                    "however": 3,
                    "hurtful": 1,
                    "idea": 1,
                    "important": 2,
                    "improve": 1,
                    "interest": 1,
                    "into": 5,
                    "isn": 1,
                    "january": 1,
                    "just": 8,
                    "keep": 2,
                    "kind": 3,
                    "know": 4,
                    "known": 2,
                    "lack": 1,
                    "last": 1,
                    "late": 12,
                    "lateness": 2,
                    "lead": 1,
                    "least": 1,
                    "length": 1,
                    "lie": 1,
                    "life": 1,
                    "like": 5,
                    "likely": 1,
                    "little": 1,
                    "look": 2,
                    "lot": 2,
                    "major": 1,
                    "make": 1,
                    "makeup": 1,
                    "malleable": 1,
                    "many": 9,
                    "matter": 3,
                    "maybe": 1,
                    "mean": 2,
                    "meaningful": 1,
                    "measure": 2,
                    "memory": 2,
                    "mental": 1,
                    "mentally": 2,
                    "messy": 3,
                    "might": 5,
                    "millennials": 1,
                    "minute": 1,
                    "miss": 1,
                    "month": 1,
                    "much": 1,
                    "my": 3,
                    "myself": 1,
                    "natural": 1,
                    "naturally": 1,
                    "never": 1,
                    "now": 1,
                    "nursery": 1,
                    "office": 1,
                    "often": 1,
                    "oh": 1,
                    "okay": 2,
                    "once": 1,
                    "one": 13,
                    "order": 1,
                    "organise": 2,
                    "other": 5,
                    "ourselves": 2,
                    "out": 3,
                    "own": 1,
                    "pacie": 2,
                    "part": 2,
                    "participant": 1,
                    "particular": 1,
                    "pass": 1,
                    "people": 9,
                    "perception": 1,
                    "perhaps": 1,
                    "person": 3,
                    "personality": 9,
                    "pick": 1,
                    "plan": 1,
                    "plane": 1,
                    "pluck": 1,
                    "power": 1,
                    "practice": 1,
                    "pretty": 2,
                    "probably": 1,
                    "problem": 2,
                    "prophecy": 1,
                    "prospective": 1,
                    "psychologist": 1,
                    "psychology": 1,
                    "punctual": 1,
                    "punctuality": 2,
                    "punctually": 1,
                    "put": 1,
                    "question": 2,
                    "quite": 2,
                    "rather": 1,
                    "re": 3,
                    "real": 2,
                    "really": 6,
                    "recent": 1,
                    "recognise": 1,
                    "regularly": 1,
                    "research": 1,
                    "result": 1,
                    "retrain": 2,
                    "reveal": 1,
                    "room": 1,
                    "routine": 1,
                    "sabotage": 1,
                    "same": 1,
                    "say": 1,
                    "scale": 2,
                    "schedule": 1,
                    "see": 1,
                    "seem": 2,
                    "self": 2,
                    "seriously": 1,
                    "set": 4,
                    "show": 1,
                    "similar": 1,
                    "slightly": 2,
                    "slip": 1,
                    "slow": 1,
                    "social": 3,
                    "some": 5,
                    "someone": 1,
                    "something": 5,
                    "sorry": 2,
                    "sound": 1,
                    "speed": 1,
                    "start": 1,
                    "stone": 1,
                    "struggle": 2,
                    "study": 3,
                    "subject": 1,
                    "suggest": 1,
                    "suppose": 1,
                    "survey": 1,
                    "take": 1,
                    "task": 3,
                    "tend": 2,
                    "tendency": 1,
                    "term": 1,
                    "than": 2,
                    "them": 1,
                    "then": 1,
                    "there": 6,
                    "therefore": 1,
                    "thing": 7,
                    "think": 1,
                    "though": 1,
                    "tidy": 1,
                    "tight": 1,
                    "time": 17,
                    "timebender": 2,
                    "timebenders": 3,
                    "timebending": 1,
                    "timekeeper": 1,
                    "track": 2,
                    "train": 2,
                    "trait": 5,
                    "try": 3,
                    "understand": 1,
                    "university": 1,
                    "up": 1,
                    "use": 1,
                    "usuall": 1,
                    "very": 4,
                    "video": 1,
                    "walk": 1,
                    "want": 1,
                    "washington": 1,
                    "watch": 1,
                    "way": 3,
                    "week": 2,
                    "what": 4,
                    "when": 9,
                    "whether": 2,
                    "which": 1,
                    "who": 7,
                    "work": 4,
                    "world": 1,
                    "worse": 1,
                    "worst": 2,
                    "yougov": 1,
                }
            },
            {
                id: 2,
                engTitle: "Who Was: Walt Disney",
                twTitle: "華特迪士尼的生平故事",
                thumbnail: "https://i3.ytimg.com/vi/9PmwUvW1NJ4/maxresdefault.jpg",
                url: "https://www.youtube.com/watch?v=9PmwUvW1NJ4",
                frequency: {
                    "about": 1,
                    "advertisement": 1,
                    "after": 2,
                    "again": 1,
                    "already": 1,
                    "america": 1,
                    "american": 1,
                    "among": 1,
                    "angeles": 1,
                    "animate": 2,
                    "animation": 1,
                    "animator": 1,
                    "around": 1,
                    "art": 2,
                    "artist": 1,
                    "bambi": 1,
                    "bear": 1,
                    "become": 1,
                    "begin": 2,
                    "big": 1,
                    "brother": 1,
                    "burbank": 1,
                    "california": 3,
                    "cancer": 1,
                    "capacity": 1,
                    "cartooning": 1,
                    "century": 1,
                    "change": 1,
                    "character": 1,
                    "chicago": 1,
                    "come": 1,
                    "comedic": 1,
                    "company": 4,
                    "conglomerate": 2,
                    "correspondence": 1,
                    "course": 1,
                    "create": 3,
                    "december": 2,
                    "demand": 1,
                    "die": 1,
                    "discover": 1,
                    "disneyland": 2,
                    "down": 1,
                    "dumbo": 1,
                    "dwarf": 1,
                    "elias": 1,
                    "end": 1,
                    "enter": 1,
                    "entertainment": 2,
                    "entirely": 1,
                    "ever": 1,
                    "family": 1,
                    "fantasia": 1,
                    "feat": 1,
                    "feature": 1,
                    "film": 4,
                    "first": 2,
                    "florida": 1,
                    "follow": 2,
                    "forever": 1,
                    "form": 1,
                    "founder": 1,
                    "high": 1,
                    "himself": 1,
                    "iconic": 1,
                    "idea": 1,
                    "illinois": 1,
                    "independent": 1,
                    "interest": 1,
                    "iwerks": 2,
                    "keep": 1,
                    "large": 1,
                    "late": 1,
                    "length": 1,
                    "long": 1,
                    "los": 1,
                    "lung": 1,
                    "many": 1,
                    "mickey": 1,
                    "midwestern": 1,
                    "month": 1,
                    "mouse": 1,
                    "move": 2,
                    "narrative": 1,
                    "new": 4,
                    "next": 1,
                    "one": 3,
                    "only": 1,
                    "open": 3,
                    "out": 1,
                    "overseas": 1,
                    "park": 2,
                    "pinocchio": 1,
                    "producer": 1,
                    "project": 2,
                    "prove": 1,
                    "quickly": 1,
                    "rank": 1,
                    "regularly": 1,
                    "release": 1,
                    "roy": 1,
                    "school": 1,
                    "seek": 1,
                    "seven": 1,
                    "short": 1,
                    "shut": 1,
                    "sketch": 1,
                    "small": 1,
                    "snow": 2,
                    "soon": 1,
                    "spokesman": 1,
                    "start": 1,
                    "state": 1,
                    "studio": 5,
                    "study": 1,
                    "supervision": 1,
                    "surgery": 1,
                    "th": 1,
                    "theme": 1,
                    "though": 2,
                    "time": 1,
                    "top": 1,
                    "ub": 1,
                    "under": 1,
                    "undergo": 1,
                    "unite": 1,
                    "up": 1,
                    "venture": 1,
                    "walter": 1,
                    "white": 2,
                    "who": 1,
                    "work": 1,
                    "world": 2,
                    "would": 2
                }
            },
            {
                id: 3,
                engTitle: "How do you learn to speak a language?",
                twTitle: "你如何學習說一門語言？",
                url: "https://www.youtube.com/watch?v=wkjSBC-_bDA",
                thumbnail: "https://i3.ytimg.com/vi/wkjSBC-_bDA/maxresdefault.jpg",
                frequency: {
                    "able": 2,
                    "about": 5,
                    "accord": 2,
                    "account": 1,
                    "add": 2,
                    "afraid": 1,
                    "after": 2,
                    "again": 1,
                    "age": 1,
                    "aim": 1,
                    "all": 4,
                    "alone": 1,
                    "altogether": 2,
                    "another": 4,
                    "answer": 1,
                    "any": 2,
                    "approximately": 2,
                    "around": 1,
                    "ask": 1,
                    "authentic": 1,
                    "base": 3,
                    "basic": 3,
                    "bbc": 1,
                    "become": 1,
                    "before": 2,
                    "begin": 1,
                    "believe": 1,
                    "benefit": 1,
                    "bien": 1,
                    "bilingual": 1,
                    "bit": 2,
                    "boost": 1,
                    "brain": 1,
                    "brainpower": 1,
                    "bring": 2,
                    "britain": 1,
                    "british": 1,
                    "briton": 1,
                    "bye": 1,
                    "call": 1,
                    "cent": 1,
                    "change": 2,
                    "claim": 1,
                    "closer": 1,
                    "com": 1,
                    "communicate": 1,
                    "conjurer": 2,
                    "context": 1,
                    "council": 1,
                    "country": 1,
                    "course": 2,
                    "culture": 1,
                    "czech": 1,
                    "de": 1,
                    "decline": 1,
                    "den": 1,
                    "depend": 1,
                    "desu": 1,
                    "develop": 1,
                    "difficulty": 2,
                    "dobr": 1,
                    "don": 6,
                    "doubt": 1,
                    "down": 1,
                    "early": 1,
                    "easy": 1,
                    "end": 1,
                    "english": 9,
                    "enjoy": 1,
                    "enough": 1,
                    "espa": 1,
                    "every": 1,
                    "exactly": 1,
                    "example": 4,
                    "expect": 1,
                    "explain": 1,
                    "extinction": 1,
                    "fail": 1,
                    "fascinate": 1,
                    "finally": 1,
                    "first": 1,
                    "five": 1,
                    "fluent": 3,
                    "fluently": 1,
                    "foreign": 1,
                    "forget": 1,
                    "form": 5,
                    "found": 1,
                    "foundation": 3,
                    "french": 1,
                    "frequency": 9,
                    "frequent": 2,
                    "function": 1,
                    "general": 1,
                    "get": 1,
                    "give": 3,
                    "go": 1,
                    "good": 13,
                    "goodbye": 2,
                    "goodness": 1,
                    "grow": 1,
                    "hadn": 1,
                    "half": 1,
                    "happen": 1,
                    "hard": 1,
                    "hasta": 1,
                    "hear": 1,
                    "hello": 1,
                    "help": 3,
                    "here": 5,
                    "high": 4,
                    "hope": 2,
                    "how": 6,
                    "identify": 1,
                    "important": 2,
                    "impressive": 1,
                    "improve": 1,
                    "inflection": 4,
                    "interest": 1,
                    "its": 2,
                    "ja": 1,
                    "jak": 1,
                    "japan": 1,
                    "japanese": 1,
                    "job": 1,
                    "just": 3,
                    "know": 8,
                    "knowledge": 1,
                    "language": 23,
                    "late": 1,
                    "leaf": 1,
                    "learn": 26,
                    "learner": 2,
                    "lemma": 2,
                    "lemmas": 2,
                    "less": 1,
                    "linguist": 1,
                    "listen": 1,
                    "little": 1,
                    "lose": 1,
                    "low": 1,
                    "lower": 3,
                    "luego": 1,
                    "madrid": 1,
                    "make": 2,
                    "mandarin": 1,
                    "many": 13,
                    "master": 6,
                    "may": 3,
                    "maybe": 1,
                    "mean": 8,
                    "minute": 2,
                    "mistake": 1,
                    "monolingual": 1,
                    "mother": 1,
                    "much": 1,
                    "muy": 1,
                    "my": 2,
                    "na": 1,
                    "namae": 1,
                    "name": 1,
                    "native": 1,
                    "ne": 1,
                    "need": 2,
                    "new": 4,
                    "news": 1,
                    "next": 2,
                    "nine": 1,
                    "notice": 1,
                    "now": 4,
                    "number": 1,
                    "occur": 1,
                    "often": 2,
                    "ol": 1,
                    "one": 3,
                    "ontario": 1,
                    "other": 2,
                    "ourselves": 1,
                    "people": 1,
                    "per": 1,
                    "plural": 1,
                    "poco": 1,
                    "potentially": 1,
                    "practice": 1,
                    "practise": 3,
                    "professor": 5,
                    "programme": 1,
                    "provide": 1,
                    "question": 1,
                    "quot": 2,
                    "radio": 1,
                    "re": 2,
                    "really": 2,
                    "reason": 1,
                    "recently": 1,
                    "recognise": 1,
                    "refer": 2,
                    "remind": 2,
                    "research": 1,
                    "resolution": 1,
                    "right": 3,
                    "risk": 1,
                    "say": 4,
                    "scientist": 1,
                    "se": 1,
                    "second": 1,
                    "sentence": 3,
                    "set": 1,
                    "shall": 1,
                    "shledanou": 1,
                    "show": 3,
                    "sign": 1,
                    "simple": 3,
                    "single": 1,
                    "skill": 2,
                    "slow": 1,
                    "sober": 1,
                    "some": 2,
                    "something": 1,
                    "sound": 1,
                    "spanish": 5,
                    "speak": 8,
                    "speaker": 1,
                    "start": 3,
                    "still": 1,
                    "structure": 1,
                    "struggle": 1,
                    "stuart": 4,
                    "student": 3,
                    "study": 4,
                    "stuff": 1,
                    "such": 1,
                    "suggest": 2,
                    "surely": 1,
                    "survey": 1,
                    "taiwan": 1,
                    "take": 1,
                    "term": 1,
                    "than": 2,
                    "thank": 1,
                    "them": 1,
                    "there": 10,
                    "thing": 2,
                    "think": 2,
                    "thoroughly": 2,
                    "thousand": 2,
                    "time": 2,
                    "tip": 1,
                    "today": 2,
                    "tongue": 2,
                    "topic": 1,
                    "travel": 1,
                    "try": 2,
                    "two": 1,
                    "un": 1,
                    "understand": 1,
                    "unesco": 1,
                    "university": 1,
                    "use": 1,
                    "very": 3,
                    "vocabulary": 4,
                    "wa": 1,
                    "waste": 1,
                    "watashi": 1,
                    "webb": 5,
                    "welcome": 1,
                    "weren": 1,
                    "western": 1,
                    "what": 3,
                    "whatever": 1,
                    "when": 1,
                    "which": 7,
                    "win": 1,
                    "without": 2,
                    "word": 22,
                    "world": 3,
                    "would": 3,
                    "wrong": 1,
                    "year": 6,
                }
            }
        ]
    }, [])

    const [selectedVideo, setSelectedVideo] = useState([])
    const [frequencyArray, setFrequencyArray] = useState([])


    useEffect(() => {

        setFrequencyArray([])

        const combinedFrequency = videos.reduce((result, video) => {
            if (!selectedVideo.includes(video.id)) {
                return result
            }
            for (const word in video.frequency) {
                if (result[word]) {
                    result[word] += video.frequency[word];
                } else {
                    result[word] = video.frequency[word];
                }
            }
            return result;
        }, {});

        let result = Object.entries(combinedFrequency).map(([word, count]) => ({word, count}));
        result = result.filter((item) => item.count > 1)

        result.sort((a, b) => b.count - a.count);

        if (result.length > 60) {
            result.length = 60
        }

        setFrequencyArray(result)

    }, [selectedVideo, videos])


    return <>
        <div className="flex justify-center gap-6">
            {
                videos.map((video) => {
                    return <Card
                        key={video.id}
                        className={`cursor-pointer flex-1 ${selectedVideo.includes(video.id) ? "border-solid border-lime-300 border-2" : ""}`}
                        onClick={() => {
                            if (selectedVideo.includes(video.id)) {
                                setSelectedVideo(selectedVideo.filter(item => item !== video.id))
                            } else {
                                setSelectedVideo([...selectedVideo, video.id])
                            }
                        }}
                    >
                        <CardHeader>
                            <img
                                src={video.thumbnail}
                                alt="thumbnail"
                            />
                        </CardHeader>
                        <CardBody>
                            <Typography color="blue-gray" className="select-none font-medium mb-2">
                                {video.twTitle}
                            </Typography>
                            <Typography color="blue-gray" className="select-none font-medium">
                                {video.engTitle}
                            </Typography>
                        </CardBody>
                        <CardFooter className="flex justify-end">
                            <Tooltip content="在新視窗中開啟">
                                <Link className="w-6 h-6" to={video.url} target="_blank" rel="noopener noreferrer">
                                    <ArrowTopRightOnSquareIcon className="w-6 h-6"/>
                                </Link>
                            </Tooltip>
                        </CardFooter>
                    </Card>
                })
            }
        </div>
        <div className="p-16 flex gap-4 flex-wrap justify-center items-center">
            {frequencyArray.length === 0 && <Typography>請點選你有興趣的影片，看看哪些單字更重要！</Typography>}

            {
                frequencyArray.map((item) => {
                    return <Chip
                        className="capitalize"
                        size="lg"
                        variant="ghost"
                        value={item.word}
                        color={item.count > 15 ? "red" : item.count > 10 ? "amber" : item.count > 5 ? "green" : "gray"}/>;
                })
            }
        </div>
    </>
}